body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.specieshover:hover {
  color: #FFB81C !important;
  transition: color 0.7s ease;
}

.edithover:hover {
  color: #1565c0;
}

.deletehover:hover {
  color: #1565c0 ;
}

.media {
  transition: transform 0.3s ease-in-out;
  transform: scale(1);
}

.media:hover {
  transform: scale(1.05) ;
}
